.eyecatcher {

	@include media-breakpoint-up(xl) {
		margin-top: -110px;
	}

	&.large {
		min-height: 6vh !important;
		z-index: -1;

		&::before {
			opacity: 0;
		}
		.vimeo-video {
			max-height: 96vh;
			width: 100%;
			overflow: hidden;
			@include media-breakpoint-down(md) {
				position: relative;
				margin-bottom: 12vh;
			}
			iframe {
				width: 100vw;
				height: 100%;
				aspect-ratio: 16/9;
			}
		}

		div.video-link {
			position: absolute;
			top: 40%;
			left: 50%;
			z-index: 990;
			transform: translateX(-50%);
			ul {
				li {
					aspect-ratio: 1/1;
					display: block;
					width: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					&:hover {
						i {
							color: #c7420b;
						}
					}
					.list-item-title {
						display: none;
					}
					i {
						font-size: 24px;
						color: $white;
						position: absolute;
						left: 45%;

						transform: translate(-50%, -50%);
					}
					&::before {
						content: "";
						position: relative;
						z-index: -1;
						width: 100%;
						height: 100%;

						opacity: 0.3;
						background: rgba(#fff, 0.3);
						border-radius: 50%;
						animation: pulse 3s ease infinite;
					}
					@keyframes pulse {
						to {
							transform: scale(1.1);
							opacity: 0.8;
						}
					}
				}
			}
		}
	}

	&-owl-carousel {
		&.large {
			min-height: unset !important;

			.owl-stage-outer {
				.owl-stage {
					.owl-item {
						height: 96vh;
						@include media-breakpoint-down(md) {
							height: 45vh;
						}

						.item {
							min-height: unset !important;
						}
					}
				}
			}
		}
		.owl-nav,
		.owl-dots {
			display: none;
		}
		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					// height: 96vh;
					// @include media-breakpoint-down(md) {
					// 	height: 35vh;
					// }
					.item {
						// min-height: unset !important;
						min-height: 46vh;
						.owl-container {
							display: flex;
							justify-content: center;
							.owl-caption {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.page-block.logo {
		min-width: 0;
		margin: 0;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 50px;
		z-index: 99;
		img {
			max-height: 50%;
			@include media-breakpoint-up(xl) {
				max-height: 100%;
			}
		}
	}

	&:not(.large) {
		height: 50vh !important; // overide;
		min-height: 400px !important; // overide;
		max-height: 550px !important; // overide;

		.owl-carousel {
			min-height: unset !important; // overide

			.item {
				height: 50vh !important; // overide
				min-height: 400px !important; // overide;
				max-height: 550px !important; // overide;
				padding: unset; // overide
			}
		}
	}

}
