&.accommodation-search-book {
    .header .container-one-column .container-holder .column div.search_book_button {
        display: none !important;
    }

    .content-section {
        .container-holder {
            @include media-breakpoint-down(sm) {
                margin-left: -15px !important;
                margin-right: -15px !important;
            }
        }
    }
}

&.accommodation-object-detail,
&.accommodation-detail {
    .lead-section {
        background-color: unset;
        padding-bottom: 0;
    }

    .info {
        .list {
            justify-content: center;
        }
    }

    .content-section {
        padding-top: 0;
    }
}

&.accommodation-book {
    padding-top: 0;

    main {
        margin-top: 0 !important;
    }

    .container-holder {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}