.mini-sab {
	background-color: $light-pink;
	@include media-breakpoint-down(md) {
		background-color: #fff4ec;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		margin-top: 0;
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			margin-top: -3vh;
			margin-bottom: 0;
			padding-bottom: 3vh;
		}
		.container-holder {
			z-index: 1;
			position: relative;
			margin-top: -100px !important;
			padding: 0 !important;
			border: 0;
			border-radius: 4px;
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}

			.column {
				padding: 0;
				box-shadow: none !important;
				@include media-breakpoint-up(sm) {
					padding-right: 0 !important;
				}
			}
		}
	}
}
