// headings
h1 {
	@include media-breakpoint-up(xl) {
		font-size: $h1-font-size;
	}
}
h2 {
	font-size: 30px;
	@include media-breakpoint-up(xl) {
		font-size: $h2-font-size;
	}
}
h1,
h2,
h3,
h4 {
	font-family: $font-federo;
	font-weight: $font-weight-regular;
}

// img
img {
	max-width: 100%;
	height: auto;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
//h5 title
.page-custom-title {
	font-size: $h5-font-size;
	font-family: $font-nautigal;
	font-weight: $font-weight-regular;
	color: $primary;
	transform: matrix(1, -0.09, 0.09, 1, 0, 0);
}
.page-title {
	text-align: center;
}
//gallery-image
.gallery {
	&-item {
		.gallery-link {
			.gallery-img,
			.gallery-caption {
				border-radius: 10px;
			}
		}
	}
}

//gradient-block
.gradient {
	content: "";
	display: block;
	position: absolute;
	background: linear-gradient(180deg, $light-pink, rgba($light-pink, 0));
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

//container-holder
.container-holder {
	margin: 0 !important;
	@include media-breakpoint-up(sm) {
		margin: 0 (-($grid-gutter-width / 2)) !important;
	}
}

.title * {
	.highlight {
		color: $secondary !important;
	}
}
