.list-type {
    h4 {
      margin-bottom: 15px;
    }
    ul,.custom-list-extra {
      list-style: none;
      padding-left: 20px;
      li {
        position: relative;
        font-size: $font-size-base * 1.125;
        margin-bottom: 5px;
        &::before {
          content: "\f111";
          position: absolute;
          top: 20%;
          left: -18px;
          font-family: $font-awesome;
          font-size: $font-size-base / 2;
          color: $primary;
          font-weight: $font-weight-bold;
          opacity: 0.8;
        }
      }
    }
    ol {
      padding-left: 0;
      list-style: none;
      counter-reset: li;
      li {
        font-size: $font-size-base * 1.125;
         &::before {
          counter-increment: li;
          content: counter(li) ".";
          margin-right: 10px;
          margin-bottom: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $dark-grey;
          font-weight: $font-weight-bold;
          opacity: 0.8;
        }
      }
    }
    .custom-list {
      padding-left: 0 !important;
      li {
        padding-left: 20px;

        &::before {
          content: "\f00c" !important;
          font-family: $font-awesome;
          font-size: $font-size-base_sm !important;
          left: 0 !important;
          top: 10%;
        }
      }
    }
    .custom-list-extra {
      margin-bottom: 30px;
    }
  }
  