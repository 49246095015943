&:not(:has(section.eyecatcher)) {
	main {
		margin-top: 6vh;
	}
	.header {
		background-color: $secondary !important;
		.page-block.logo {
			top: unset !important;
			a {
				// background: url("/images/logo-s.png") no-repeat;
				width: 110;
				height: 92px;
				display: block;
			}
		}
	}
}
.header {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: unset;
	@include media-breakpoint-up(lg) {
		position: fixed;
		z-index: 999;
	}
	// responsive custom styling
	.page-block.menu-toggle {
		min-width: 0;
		.navbar-toggler {
			display: block !important;
			z-index: 2;
			position: relative;
			color: white;
			&[aria-expanded="true"] {
				.navbar-toggler-icon {
					i {
						&::before {
							content: "\F00D";
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				position: fixed;
				left: 15px;
				top: 15px;
			}
		}
	}

	.page-block.site-switcher {
		margin: 0;
	}
	.container-one-column {
		@include media-breakpoint-down(md) {
			padding-left: 0;
			padding-right: 0;
		}
		.container-holder {
			.column {
				background: transparent;
				@include media-breakpoint-down(md) {
					background: $secondary;
				}
				.page-block.menu-toggle {
					margin: 30px;
					@include media-breakpoint-down(md) {
						margin: 0;
					}
				}
				.page-block.logo {
					min-width: 0;
					margin: 0;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					top: 50px;
					@include media-breakpoint-down(md) {
						position: relative;
						top: 0;
						margin-top: 10px;
					}
					img {
						max-height: unset;
						max-width: 150px;
						
						@include media-breakpoint-up(lg) {
							max-width: 240px;
							filter: drop-shadow(2px 2px 0 rgba($black, 0.5));
						}

						@include media-breakpoint-up(xl) {
							max-width: 376px;
						}
					}
				}
				div.search_book_button {
					z-index: 2;
					position: fixed;
					right: 0;
					top: 100px;
					background-image: url("/images/ticket-mobile.svg");
					background-size: cover;
					background-repeat: no-repeat;
					display: flex !important;
					align-items: center;
					transition: 0.5s ease;
					transform: rotate(-5deg) translateX(16%);
					width: 190px;
					height: 135px;
					padding: 20px 20px 26px;
					
					@include media-breakpoint-up(lg) {
						transform: rotate(-5deg) translateX(45%);
						padding: 25px;
						width: 450px;
						height: 250px;
						background-image: url("/images/ticket.svg");
						top: 60px;

					}

					&:hover {
						transform: rotate(-5deg) translateX(20%);
						transition: 0.5s ease;
						
						@include media-breakpoint-up(lg) {
							transform: rotate(-5deg) translateX(40%);
						}
					}
					ul {
						display: flex !important;
						flex-flow: column;
						max-width: 100px;
						width: 100%;
						height: 100%;
						position: relative;
						margin: 0;
						
						@include media-breakpoint-up(lg) {
							max-width: 162px;
						}

						li {
							padding: 1rem;
							a {
								display: flex;
								flex-flow: column;
								justify-content: space-between;
								position: absolute;
								top: 0;
								left: 0;
								height: 100%;
								width: 100%;
								text-decoration: none;
							}
							&:nth-child(1) a {
								flex-flow: column-reverse !important;
								.list-item-title {
									@extend.btn, .btn-primary;
									text-align: center;
									display: flex;
									justify-content: center;
									font-size: 18px;

									@include media-breakpoint-down(md) {
										padding: 0.15rem 0.35rem !important;
										font-size: 12px !important;
									}
								}
								.list-item-suffix {
									color: $white;
									font-size: 12px;
									line-height: 1;
									
									@include media-breakpoint-up(lg) {
										font-size: 20px;
									}
								}
							}
							&:nth-child(2) a {
								justify-content: center;

								.list-item-title {
									font-family: $font-nautigal;
									font-size: 22px;
									line-height: 20px;
									color: $dar-grey-blue;

									@include media-breakpoint-up(lg) {
										font-size: 36px;
										line-height: 32px;	
									}
								}
								.list-item-suffix {
									font-size: 14px;
									color: $white;
									font-family: $font-federo;
									margin-bottom: 0.875rem;
									overflow: hidden;
									
									@include media-breakpoint-up(lg) {
										margin-bottom: 1.25rem;
										font-size: 18px;
									}
								}
							}
						}
					}
				}
				div.page-block.site-switcher {
					display: flex;
					justify-content: flex-end;
					margin-top: $grid-gutter-width;
					@media screen and (max-width: 320px) {
						transform: translateX(30px) !important;
					}
					@include media-breakpoint-down(sm) {
						transform: translateX(10px);
					}
					@include media-breakpoint-up(sm) {
						margin-top: 0;
					}
					.navbar-nav.language {
						background-color: white;
						border-radius: 19px;
						.nav-item {
							&:not(:last-child) {
								.nav-link {
									padding-right: 0;
									@include media-breakpoint-down(sm) {
										padding-left: 5px;
									}
								}
							}
						}
					}
				}
				.side_panel.navbar-collapse.menu {
					position: fixed;
					left: 0;
					top: 0;
					width: 100vw;
					transform: translateX(-100vw);
					height: 100%;
					background: $secondary;
					padding: 5.625rem 2rem 2rem;
					transition: 0.25s;
					z-index: 11;
					overflow: visible;
					@include media-breakpoint-up(md) {
						transform: translateX(-400px);
						width: 90%;
						max-width: 400px;
						padding: 5.625rem 2rem;
						&::before {
							content: "";
							display: block;
							opacity: 0;
							position: fixed;
							top: 0;
							transform: translateX(200vw);
							right: 0;
							background: rgba($black, 0.5);
							bottom: 0;
							width: 100vw;
							transition: all 250ms;
							backdrop-filter: blur(3px);
						}
					}
					@include media-breakpoint-down(lg) {
						&.collapse {
							display: block;
						}
					}
					&.show {
						transform: translateX(0);
						transition: 0.5s ease-in !important;

						&::before {
							display: inline-block;
							opacity: 1 !important;
							transform: translateX(100vw);
							transition: 0.5s ease-in !important;
							transition-delay: 0.25s;
						}
					}
					&.side_panel_opened {
						.nav-item {
							&:not(.active_side_panel) {
								display: block !important; //!important was needed to override base styles
							}
						}
					}
					.side-panel-close {
						display: none;
					}
					.navbar-nav {
						width: 100%;
						flex-direction: column;
						justify-content: flex-start;
						height: 100%;
						overflow: auto;

						@include media-breakpoint-up(lg) {
							justify-content: center;
						}

						> li {
							a {
								font-size: $font-size-base;
								font-weight: 600;
								color: $white;
								padding: 0.6rem 0;

								&:hover {
									text-decoration: none;
								}

								&.active {
									> a {
										color: $black;
										text-decoration: underline;
									}
								}
							}

							&:not(:last-child) {
								border-bottom: 1px solid $white;
							}

							.dropdown-toggle {
								&::after {
									font-family: $font-awesome;
									font-size: $font-size-base_sm;
									font-weight: 400;
									border: none;
									position: absolute;
									top: 50%;
									margin-left: 10px;
									content: "\F178";
									transform: translateY(-50%);
									transition: 0.25s ease-in-out;
								}
							}

							&.nav-item.show {
								position: absolute;
								height: 100%;
								width: 100%;
								max-width: 400px;
								background: $secondary;
								padding: 0 30px;
								left: 0;
								z-index: 1;
								transition: none;
								.dropdown-toggle.nav-link {
									display: none;
								}

								ul.dropdown-menu.show {
									position: static !important;
									transform: none !important;
									margin: 0;
									padding: 0 0 10px;
									border: none;
									background: none;
									width: 100%;
									display: flex;
									height: 100%;
									flex-direction: column;
									justify-content: center;
									transition: none;

									.menu-side-panel-header {
										display: flex;
										align-items: center;
										justify-content: space-between;
										margin-bottom: 80px;
										cursor: pointer;

										.go-back {
											position: relative;

											&::after {
												font-family: $font-awesome;
												font-size: $font-size-base * 1.5;
												font-weight: 400;
												border: none;
												position: absolute;
												top: 50%;
												content: "\F178" !important; // !important needed to override base css
												transform: translateY(-50%)
													rotate(180deg);
											}
										}

										span {
											font-size: $font-size-base * 1.5;
											font-weight: 700;
											color: $white;
										}
									}

									.nav-item {
										&:not(:last-child) {
											border-bottom: 1px solid $white;
										}

										.nav-link {
											font-size: $font-size-base;
											font-weight: 600;
											color: $white;
											padding: 0.6rem 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// responsive mobile header order.
	.page-block.menu-toggle {
		order: 0;
	}
	.page-block.logo {
		order: 1;
	}
	.page-block.site-switcher {
		order: 2;
	}
	.page-block.menu {
		order: 3;
	}

	// Desktop resets

	.page-block.menu-toggle {
		order: unset;
	}
	.page-block.logo {
		order: unset;
	}
	.page-block.site-switcher {
		order: unset;
	}
	.page-block.menu {
		order: unset;
	}

	.page-block.site-switcher {
		display: flex;
		justify-content: flex-end;
		margin-left: 25px;
		flex-grow: 0;
		@include media-breakpoint-down(md) {
			margin-left: auto;
			margin-top: unset !important;
		}
	}

	.page-block.menu-toggle {
		margin: 0 0 0 auto;
		.navbar-toggler {
			background: $purple;
			width: 60px;
			aspect-ratio: 1/1;
			border-radius: 50%;
			font-size: 24px;
			z-index: 101;
		}
	}

	&.sticky {
		background-color: $secondary;
		.container-one-column {
			.container-holder {
				.column {
					.page-block.logo {
						top: 5px;
						a {
							background: url("/images/logo-s.png") no-repeat;
							width: 83px;
							height: 70px;
							display: block;
						}
						img {
							display: none;
						}
					}
				}
			}
		}
	}
}
&.theme-demo {
	.header {
		.container-one-column {
			.container-holder {
				margin: 0 (-($grid-gutter-width / 2)) !important;
				.column {
					background-color: $secondary;
					.page-block.logo {
						top: 10px;
						a {
							background: url("/images/logo-s.png") no-repeat;
							width: 83px;
							height: 70px;
							display: block;
						}
						img {
							display: none;
						}
					}
				}
			}
		}
	}
}
