.card-grid.grid,
.collection.grid {
	.grid-items {
		.item {
			// margin: 0 0 ($grid-gutter-width * 1.33) 0;
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			.card {
				box-shadow: $box-shadow;
				border-radius: 6px;
				border: 3px solid $white;

				&-image {
					background: transparent;
				}

				&-body {
					background-color: $secondary;
					padding: 35px 20px;
                    border-radius: 6px;
					.card-caption {
						display: flex;
						flex-direction: column;
						.card-title,
						.card-subtitle {
							font-family: $font-federo;
							font-weight: $font-weight-regular;
						}
						.card-title-link {
							order: 2;
							color: white;
							&:hover {
								color: $lipstick-pink;
							}
						}
						.card-title {
							font-size: $font-size-base * 1.875;
							color: white;
						}
						.card-subtitle {
							order: 1;
							color: $lipstick-pink;
							font-size: 20px;
						}
						.card-description {
							order: 3;
							font-weight: $font-weight-light;
							color: white;
							//margin-bottom: $grid-gutter-width * 1.33;
						}
						.card-price {
							color: $lipstick-pink;
						}
					}
					.card-buttons {
						.card-btn {
							@extend .btn-primary-white;
						}
					}
				}
			}
		}
	}
}
.card-grid.grid {
	.grid-items {
		.item {
			// &:nth-child(3n-1) {
			// 	@include media-breakpoint-up(lg) {
			// 		transform: translateY(-50px);
			// 	}
			// }
			.card {
				.card-buttons {
					.card-btn {
						background: transparent;
						border: 0;
						box-shadow: none;
						text-decoration: underline !important;
						font-size: $font-size-base * 1.125;
						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
}
