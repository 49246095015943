/* 	bg-light/bg-dark
    ========================================================================== */
section.bg-light,
section.bg-dark {
	margin: 0;
	padding: 6vh 0;
}

// fix card text color smooth widgets
.bg-dark {
	.list-item-text {
		p {
			color: #000;
		}
	}
}

/* 	lead-section
    ========================================================================== */
.lead-section {
	padding: ($grid-gutter-width * 1.33) 0;
	background-color: $light-pink;

	.container-one-column {
		.container-holder {
			.column {
				div.page-block.vocabulary-term-list {
					margin-top: 3vh;
					@include media-breakpoint-down(md) {
						max-width: 90%;
						margin: auto;
					}
					justify-content: center;
					padding-bottom: $grid-gutter-width / 2;
					ul {
						width: 100%;
						display: flex;
						gap: 0.75rem;
						justify-content: space-between;
						li {
							font-size: 20px;
							font-family: $font-federo;
							font-weight: $font-weight-regular;
							text-transform: capitalize;
							span.highlight {
								color: $orange;
							}
							&::before {
								content: "\F00C";
								color: $parsley;
								font-family: $font-awesome;
								font-weight: $font-weight-bold;
								margin-right: 10px;
							}
						}
					}
				}
				.page-block.title,
				.page-block.wysiwyg {
					max-width: 800px;
					margin: 0 auto;
					text-align: center;
					h5 {
						@extend .page-custom-title;
						font-size: 100px;
						margin-top: $grid-gutter-width;
						@include media-breakpoint-down(md) {
							font-size: 48px;
						}
					}
					p {
						max-width: 570px;
						margin: auto;
						font-weight: $font-weight-light;
						font-size: $font-size-base * 1.125;
						&:has(+ p) {
							margin-bottom: 1rem;
						}
					}
				}
			}
		}
	}

	/* bundles */
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
	}
	.info {
		margin: auto;
		//intro
		.page-block {
			h5 {
				@extend .page-custom-title;
				/* margin: auto; */
				text-align: center;
			}
		}
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;
			li {
				margin: 0 15px;
				color: $secondary;
				font-weight: $font-weight-semibold;
			}
			.icon {
				margin-right: 5px;
			}
			.label {
				font-size: $font-size-base * 1.125;
			}
		}
	}
	&.two {
		background: $white;
	}
}

&.service-category-detail {
	.lead-section {
		.container-one-column {
			.container-holder {
				.column {
					.page-block.title,
					.page-block.wysiwyg {
						text-align: center;
					}
				}
			}
		}
	}
}

/* 	content-section bg-light
    ========================================================================== */
.content-section.bg-light {
	position: relative;
	background-color: white !important;
	padding: ($grid-gutter-width * 2.33) 0 0;
	// &::before {
	// 	@extend .gradient;
	// 	@include media-breakpoint-up(sm) {
	// 		height: 500px;
	// 	}
	// }
	.container-one-column.container {
		padding-bottom: $grid-gutter-width * 3.33;
		div.page-block.link {
			display: flex;
			justify-content: center;
		}
		.card-buttons {
			.card-btn {
				color: white !important;
				padding: 0 !important;
			}
		}
	}
	.container-one-column.facility {
		background: linear-gradient(
			180deg,
			rgba($faded-purple, 0),
			$faded-purple
		);
		position: relative;
		overflow: hidden;
		&::before {
			content: "";
			display: block;
			position: absolute;
			background-color: white;
			top: 0;
			left: 0;
			height: 200px;
			width: 100%;
		}
		.container-holder {
			.column {
				.grid {
					&-items {
						.item {
							@include make-col(6);
							padding: 0 ($grid-gutter-width / 3);
							@include media-breakpoint-up(lg) {
								padding: 0;
							}
							@include media-breakpoint-up(lg) {
								@include make-col(3);
							}
							&:nth-child(odd) {
								transform: translateY(-50px);
								padding: 0 0 0 ($grid-gutter-width / 3);
								@include media-breakpoint-up(lg) {
									padding: 0;
								}
							}
							.card {
								border-radius: 6px;
								position: relative;

								&:hover {
									box-shadow: $box-shadow;
								}
								.card-image,
								.card-img {
									height: 100%;
								}
								&-img-overlay {
									.card-caption {
										display: flex;
										justify-content: center;
										.card-title {
											font-size: $h5-font-size;
											font-family: $font-nautigal;
											font-weight: $font-weight-regular;
											position: absolute;
											bottom: $grid-gutter-width;
										}
										.card-subtitle,
										.card-description {
											display: none !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.home {
		padding-bottom: 0 !important;
		position: unset !important;
		background: #fff4ec !important;
		.card {
			border: none !important;
			background: none !important;
		}
		.container {
			padding-bottom: 0;
			margin-bottom: -18vh !important;
		}
		@include media-breakpoint-down(md) {
			margin-top: 0;
			padding-top: 0;
		}
	}
}

/* 	content-section bg-dark
    ========================================================================== */
.content-section.bg-dark {
	background-color: $faded-purple !important;
	padding: ($grid-gutter-width * 2) 0;
	.container-one-column.intro {
		max-width: 570px;
		margin: auto;
		text-align: center;
		.container-holder {
			.column {
				.page-block {
					h5 {
						@extend .page-custom-title;
						color: white !important;
					}
					h2 {
						font-size: $h2-font-size;
						font-family: $font-federo;
						font-weight: $font-weight-regular;
					}
					p {
						font-size: $font-size-base * 1.25;
						font-weight: $font-weight-light;
					}
				}
			}
		}
	}
	.container-one-column.activities {
		margin-top: $grid-gutter-width * 2.33;
		.container-holder {
			.column {
				.grid {
					&-items {
						.item {
							@include make-col(6);
							margin: 1rem 0;
							@include media-breakpoint-up(lg) {
								@include make-col(3);
							}
							.card {
								background: $white;
								border: 0;
								box-shadow: none;
								border-radius: 10px;
								padding-top: 3vh;
								&-body {
									@include media-breakpoint-down(md) {
										overflow: hidden;
										padding: 15px;
									}
									* {
										color: $dar-grey-blue;
									}
									background: transparent;
									max-width: 215px;
									margin: auto;
									padding: $grid-gutter-width / 2;
									.card-caption {
										text-align: center;
										.card-title-link {
											color: $dar-grey-blue;
											margin-bottom: $grid-gutter-width /
												2;
											.card-title {
												font-size: 24px;
												text-transform: none;
												font-family: $font-family-base;
												font-weight: $font-weight-bold;
												@include media-breakpoint-down(
													md
												) {
													font-size: 16px;
												}
											}
										}
										.card-description {
											&-content {
												p {
													margin-bottom: $grid-gutter-width /
														6;
													font-weight: $font-weight-light;
													font-size: $font-size-base_sm;
													opacity: 0.7;
													@include media-breakpoint-up(
														sm
													) {
														font-size: $font-size-base;
													}
												}
											}
										}
									}
									.card-buttons {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.home {
		padding-top: 20vh !important;
		padding-bottom: 12vh !important;
		margin-top: 0;
	}
}

.accommodation-section {
	background: linear-gradient(to bottom, #fff4ec, #fff);
	padding: 6vh 0;
	.container.intro {
		.column {
			text-align: center;
		}
		.subtitle {
			@extend .page-custom-title;
			color: $orange !important;
		}

		.title {
			font-size: 40px;
			font-family: $font-federo;
			font-weight: $font-weight-regular;
		}

		p {
			font-size: $font-size-base * 1.25;
			font-weight: $font-weight-light;
		}
	}
	.container.accos {
		margin-top: 6vh;
		.grid-items {
			display: flex;
			flex-flow: column;
			gap: 4vh;
			padding-top: 6vh;
			a {
				text-decoration: none !important;
			}
			.item {
				@include media-breakpoint-down(md) {
					.card-body,
					.card-caption {
						max-width: 100% !important;
					}
					.list-icons {
						position: relative !important;
					}
					.card-buttons {
						margin-left: 0 !important;
						margin-right: auto;
						max-width: 100% !important;
						text-align: left;
						width: 100% !important;
						justify-content: flex-start;
					}
				}
				max-width: 100% !important;
				flex-basis: 100% !important;
				.card:not(:has(.list-icons)) {
					.card-buttons {
						margin-left: 0 !important;
						margin-right: auto;
						max-width: 100% !important;
						text-align: left;
						justify-content: flex-start;
					}
				}
				.card {
					overflow: visible;
					border: none;
					box-shadow: 3px 3px 6px rgba(#000, 0.2);
					margin: auto;

					@include media-breakpoint-up(lg) {
						max-width: 85%;
					}

					.card-image {
						max-width: 50% !important;
						flex-basis: 50% !important;
						padding: 1rem;
						overflow: visible;
						img {
							border-radius: 10px;
							object-fit: cover;
							// transform: translateY(-6vh);
							@include media-breakpoint-up(lg) {
								aspect-ratio: 4 / 3;
							}
						}
					}
					.card-body {
						max-width: 50% !important;
						flex-basis: 50% !important;
						background: $white;
						position: relative;
						@include media-breakpoint-up(lg) {
							padding: 35px 50px 35px 20px;
						}

						.card-caption {
							// max-width: 50%;
							.list-icons {
								position: absolute;
								top: 1.5rem;
								right: 0;
								width: 39%;
								li {
									font-size: 16px;
									i {
										color: $primary;
										font-size: 18px;
										font-weight: 200;
									}
								}
							}
						}
						.card-title {
							color: $primary;
							text-decoration: none;
						}
						.card-description {
							position: static !important;
							color: $black;
						}
						.card-buttons {
							justify-content: flex-end;
							text-align: start;
							// max-width: 39%;
							// width: 40%;
							// margin-left: auto;
							.card-btn {
								@extend .btn, .btn-primary;
							}
						}
					}
					@include media-breakpoint-down(md) {
						flex-flow: column !important;
						.card-image {
							max-width: 100% !important;
							padding: 0 !important;

							flex-basis: 100% !important;
							img {
								transform: none;
							}
						}
						.card-body {
							max-width: 100% !important;
							flex-basis: 100% !important;
						}
					}
				}
				&:nth-child(even) {
					.card {
						flex-flow: row-reverse !important;
						@include media-breakpoint-down(md) {
							flex-flow: column !important;
						}

						&-body {
							@include media-breakpoint-up(lg) {
								padding: 35px 20px 35px 50px;
							}
						}
					}
				}
			}
		}
	}
}

.surroundings-section {
	.container-fluid {
		background: linear-gradient(to bottom, #fff, $faded-purple);
		margin-top: 12vh;

		.container-holder {
			.column {
				.grid-items {
					display: flex;
					gap: 0.5rem;
					.item {
						max-width: 24% !important;
						flex-basis: 24% !important;
						padding: 0 !important;
						@include media-breakpoint-down(md) {
							max-width: 45% !important;
							flex-basis: 45% !important;
						}
						&:nth-child(odd) {
							transform: translateY(-50px);
						}

						.card {
							border-radius: 6px;
							position: relative;

							&:hover {
								box-shadow: $box-shadow;
							}

							.card-image,
							.card-img {
								height: 100%;
							}

							&-img-overlay {
								.card-caption {
									display: flex;
									justify-content: center;

									.card-title {
										font-size: $h5-font-size;
										font-family: $font-nautigal;
										font-weight: $font-weight-regular;
										position: absolute;
										bottom: $grid-gutter-width;
										width: 100%;
										text-align: center;
										left: 0;
										@include media-breakpoint-down(md) {
											font-size: 28px;
											padding: 15px;
										}
									}

									.card-subtitle,
									.card-description {
										display: none !important;
									}
								}
							}
							.card-buttons {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.bon-voyage-section {
	background: rgba($faded-purple, 1);
	padding-top: 6vh;
	padding-bottom: 6vh;
	.one {
		max-width: 60% !important;
		flex-basis: 60% !important;
		text-align: center;
		display: flex !important;
		flex-flow: column !important;
		justify-content: center !important;
		align-items: center !important;

		.subtitle {
			@extend .page-custom-title;
			color: $white !important;
		}

		.title {
			font-size: $h2-font-size;
			font-family: $font-federo;
			font-weight: $font-weight-regular;
			color: $white !important;
		}

		p {
			font-size: $font-size-base * 1.25;
			font-weight: $font-weight-light;
			color: $white !important;
		}
	}
	.two {
		max-width: 40% !important;
		flex-basis: 40% !important;
		.flag {
			position: relative;
			img {
				position: relative;
			}
			&::after {
				content: url("/images/flag-overlay.png");
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.one,
		.two {
			max-width: 100% !important;
			flex-basis: 100% !important;
		}
	}
}

/* 	bundle-overview-section
    ========================================================================== */
.bundle-overview-section {
	background-color: white;
	position: relative;
	&::before {
		@extend .gradient;
		@include media-breakpoint-up(sm) {
			height: 500px;
		}
	}
}

/* 	bundle-detail-section
    ========================================================================== */
.bundle-detail-section {
	background-color: $light-pink;
	padding-bottom: $grid-gutter-width;
	.container-two-columns {
		.container-holder {
			.column {
				.page-block {
					p {
						font-weight: $font-weight-light;
						font-size: $font-size-base * 1.125;
					}
				}
			}
		}
	}
}

/* 	gallery-section {
    ========================================================================== */
.gallery-section {
	background-color: white;
	position: relative;
	&::before {
		@extend .gradient;
	}
}

/* 	content-section {
    ========================================================================== */
.content-section {
	padding-top: $grid-gutter-width * 2;
	.container-two-columns {
		.container-holder {
			.column {
				.page-block {
					p {
						max-width: 600px;
						font-weight: $font-weight-light;
						font-size: $font-size-base * 1.125;
					}
				}
			}
		}
	}
}

/* 	booking-section{
    ========================================================================== */
.booking-section {
	margin-top: ($grid-gutter-width * 2) !important;
	background-color: $secondary !important;
}
&.accommodation-detail {
	.main {
		.lead-section {
			.info-nav {
				// flex-direction: column-reverse;

				.scroll-to-content {
					z-index: 1;
					position: relative;
					margin-top: -70px;
					margin-bottom: 30px;
				}
			}
		}
	}
}
&.theme-demo {
	.main {
		.lead-section {
			margin-top: 0;
			padding: ($grid-gutter-width * 5) 0 ($grid-gutter-width * 1.33);

			@include media-breakpoint-up(xl) {
				margin-top: -($grid-gutter-width);
			}
			.container-one-column {
				.container-holder {
					.column {
						max-width: 600px;
						margin: auto;
						text-align: center;
						h5 {
							@extend .page-custom-title;
						}
						p {
							max-width: 570px;
							font-weight: $font-weight-light;
							font-size: $font-size-base * 1.125;
						}
					}
				}
			}
			&.bg-dark {
				background-color: $secondary !important;
				padding: ($grid-gutter-width * 2) 0 ($grid-gutter-width) !important;
				margin-top: 0 !important;

				.container-one-column {
					.container-holder {
						.column {
							h5 {
								color: white;
							}
						}
					}
				}
			}
		}
		.content-section {
			background-color: white;
			position: relative;
			// &::before {
			// 	@extend .gradient;
			// 	@include media-breakpoint-up(sm) {
			// 		height: 500px;
			// 	}
			// }
			.container-two-columns {
				.container-holder {
					.column {
						h5,
						h6 {
							@extend .page-custom-title;
							transform: none;
						}
						h6 {
							font-size: $h6-font-size;
						}
						p {
							font-weight: $font-weight-light;
							font-size: $font-size-base * 1.125;

							strong {
								font-weight: $font-weight-semibold;
							}
							em {
								font-style: normal;
							}
						}
						&.two {
							@extend .list-type;
						}
					}
				}
			}
		}
		.content-section.bg-dark {
			background-color: $secondary !important;
			&::before {
				content: none;
			}
			.container-two-columns {
				.container-holder {
					.column {
						h5 {
							color: $lipstick-pink;
							font-size: $font-size-base;
							transform: none;
							font-family: $font-federo;
							font-weight: $font-weight-regular;
						}
						h6 {
							color: white;
						}
						&.two {
							ul,
							ol {
								li {
									&::before {
										color: white;
									}
								}
							}
							ul + p {
								a {
									color: white;
								}
							}
							p {
								.btn-primary {
									@extend .btn-primary-white;
								}
								.btn-outline-primary {
									@extend .btn-outline-primary-transparent;
								}
								.btn-secondary,
								.btn-outline-secondary {
									@extend .btn-outline-secondary-transparent;
								}
							}
						}
					}
				}
			}
		}
	}
}

// &.contact {
// 	ul.vocabulary-term-list {
// 		li {
// 			a {
// 				i {
// 					font-size: $font-size-base * 1.5;
// 				}
// 				span {
// 					display: none;
// 				}
// 				&:hover {
// 					opacity: 0.7;
// 				}
// 			}
// 		}
// 	}
// }
