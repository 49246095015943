> footer > section {
	&.footer-top {
		padding: ($grid-gutter-width * 2) 0 ($grid-gutter-width * 3);
		position: relative;
		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 2) 0 ($grid-gutter-width * 6);
		}
		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			background: url("/images/footer.svg") no-repeat top / cover;
			height: 20px;
			width: 100%;
			@include media-breakpoint-up(sm) {
				height: 45px;
			}
			@include media-breakpoint-up(lg) {
				height: 60px;
			}
			@include media-breakpoint-up(xl) {
				height: 100px;
			}
		}
		.container-one-column {
			margin: auto;
			text-align: center;

			.container-holder {
				.column {
					.page-block {
						h5 {
							@extend .page-custom-title;
						}
						h3 {
							font-size: $h2-font-size;
							font-family: $font-federo;
							font-weight: $font-weight-regular;
							@include media-breakpoint-up(sm) {
								font-size: $h1-font-size;
							}
						}
						p {
							font-size: $font-size-base * 1.125;
							font-weight: $font-weight-light;
							&:first-of-type {
								max-width: 495px;
								margin: 0 auto ($grid-gutter-width * 1.5);
							}
						}
						ul {
							display: flex;
							flex-wrap: wrap;
							justify-content: center;
							margin: 0;
							padding: 0;
							list-style: none;
							li {
								a {
									border-radius: 24px !important;
									@extend .btn;
									@extend .btn-primary;
									i {
										margin-right: 10px;
									}
								}
								&:first-of-type {
									margin-bottom: 10px;
									@include media-breakpoint-up(sm) {
										margin-right: 10px;
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.footer-outro {
		padding: ($grid-gutter-width * 2.33) 0 ($grid-gutter-width * 1.5);
		position: relative;
		@include media-breakpoint-up(sm) {
			padding: ($grid-gutter-width * 2.33) 0;
		}
		background-color: $dar-grey-blue;
		.container-one-column {
			margin: auto;
			text-align: center;
			.container-holder {
				.column {
					.page-block.logo {
						img {
							max-width: 254px;
							max-height: 100%;
							@include media-breakpoint-up(xl) {
								max-width: 299px;
							}
						}
					}
				}
			}
		}
	}

	&.footer-sitelinks {
		padding: 4vh 0;
		background-color: $dar-grey-blue;
		.container-six-columns {
			.container-holder {
				display: flex;
				// gap: 0.5rem;
				justify-content: space-between;
				.column {
					// max-width: 19% !important;
					@include make-col(2, 10);
					// flex-basis: 19% !important;
					// padding: 0 !important;
					@include media-breakpoint-down(md) {
						@include make-col(6);
						&.five {
							max-width: 100% !important;
							flex-basis: 100% !important;
						}
					}

					@include media-breakpoint-down(sm) {
						@include make-col(12);
					}
					&.four {
						@include media-breakpoint-up(lg) {
							padding-left: 2rem!important;
						}
					}
					&.five {
						background: $faded-purple;
						border-radius: 8px;
						padding: 15px !important;
						.footer-text {
							p {
								color: $white;
							}
							h6 {
								font-family: $font-nautigal;
								font-size: 36px;
								color: $white;
								font-weight: 200;
							}
							h3 {
								font-family: $font-federo;
								font-size: 24px;
								color: $white;
								font-weight: 400;
							}
						}
					}
					&.six {
						display: none;
					}
					.page-block {
						h5 {
							font-size: $font-size-base * 1.125;
							font-weight: $font-weight-bold;
							color: white;
						}
						.list {
							&-item {
								.link {
									font-size: $font-size-base_sm;
									font-weight: $font-weight-regular;
									letter-spacing: 1.4px;
									color: white;
									text-decoration: underline;
									opacity: 0.7;
									&:hover {
										opacity: 1;
									}
								}
							}
						}
						.vocabulary-term-list {
							li {
								a {
									color: white;
								}
							}
						}
					}
					&.four {
						ul.vocabulary-term-list {
							margin-top: 10px;
							li {
								margin: 0;

								+ li {
									margin-left: 10px;
								}

								a {
									i {
										font-size: $font-size-base * 1.5;
									}
									span {
										display: none;
									}
									&:hover {
										opacity: 0.7;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.footer-socket {
		padding: 2vh 0;
		background-color: $dar-grey-blue;
		.container-one-column {
			.container-holder {
				.column {
					.page-block {
						.list {
							&-item {
								.link {
									color: $white;
									font-weight: $font-weight-regular;
									font-size: $font-size-base_xxs;
									text-decoration: none;
									letter-spacing: 1.2px;
									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
