.btn {
    display: inline-flex;
    align-items: center;
    padding: 10px 30px;
    font-size: $font-size-base * 1.125;
    font-family: $font-federo;
    font-weight: $font-weight-regular;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba($black, 0.15);
    color: $white;
    text-decoration: none !important;
    transition: 0.3s;

    /* btn-primary */
    &.btn-primary {
        background: $primary;
        border-color: $primary;
        color: $white;
        &:hover {
            background: $white;
            border-color: $primary;
            color: $primary;
        }
    }
    /* btn-primary-white */
    &-primary-white {
        @extend .btn;
        background: $white;
        border-color: $white;
        color: $secondary;
        &:hover {
            background: transparent;
            border-color: $white;
            color: $white;
        }
    }
    /* btn-outline-primary */
    &.btn-outline-primary {
        color: $primary;
        &:hover {
            color: $white;
        }
    }
    /* btn-outline-primary-transparent */
    &-outline-primary-transparent {
        @extend .btn;
        color: $white;
        border-color: $white;
        background-color: transparent;
        &:hover {
            color: white;
            opacity: 0.7;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        &:hover {
            background-color: white;
            border-color: $secondary;
            color: $secondary;
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
        color: $secondary;
        &:hover {
            color: $white;
        }
    }

    /* btn-outline-secondary-transparent */
    &-outline-secondary-transparent {
        background-color: transparent;
        border-color: transparent;
        color: $white;
        box-shadow: none;
        text-decoration: underline !important;
        padding: 0;
        &:hover {
           opacity: 0.7;
        }
    }
}

/*  btn-back
    ========================================================================== */
.btn-back {
    display: inline-flex;
    font-size: $font-size-base * 1.125;
    font-weight: $font-weight-regular;
    font-family: $font-federo;
    text-decoration: none;
    margin-bottom: 0 !important;
    &::before {
        display:inline-block;
        content: '\f0a8';
        font-weight: 300;
        margin-right: 3px;
    }
}

/*  btn-down
    ========================================================================== */
.btn-down {
    @extend .btn;
    @extend .btn-primary;
}
