// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap

// font
@import url("https://fonts.googleapis.com/css2?family=Federo&display=swap");
$font-federo: "Federo", sans-serif;
@import url("https://fonts.googleapis.com/css2?family=The+Nautigal&display=swap");
$font-nautigal: "The Nautigal", cursive;
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;600;700&display=swap");
$font-mukta: "Mukta", sans-serif;
$font-family-base: $font-mukta;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
$font-poppins: "Poppins", sans-serif;
$font-awesome: "Font Awesome 5 Pro";
// build options
$use-sticky-header: true;
$use-html-fontsize-reset: true;

// colors
$primary: #c7420b;
$secondary: #2c5760;
$tertiary: #488ddb;
$quaternary: #044389;
$light-pink: #fff4ec;
$lipstick-pink: #c98686;
$faded-purple: #966b9d;
$dark-grey: #373737;
$smokey-grey: #707070;
$dar-grey-blue: #2c4460;
$light: #bbd331;
$dark: #3d4609;
$parsley: #0e551f;
$body-color: $dark-grey;
$orange: $primary;
$purple:#966B9D;
// matrix-colors
$matrix-color-primary: #2c5760;
$matrix-color-secondary: #c7420b;
$matrix-color-tertiary: #2c4460;

/* bootstrap > typography
   ========================================================================== */
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-base: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 1.875 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 3.5 !default;
$h6-font-size: $h2-font-size !default;
